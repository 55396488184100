/*
* @Author: 曹俊杰
* @Date: 2024-10-12 15:52:18
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-12 16:05:52
*/裁决文书 列表渲染

<template>
    <div class="verdict_box" @click="$utils.ossOpen(verdict)">
        {{ $utils.nameRender(verdict) }}
    </div>
</template>

<script>
export default {
    props: {
        verdict: {
            type: String,
            default: ''
        }
    }

}
</script>

<style lang="scss" scoped>
.verdict_box {
    padding-left: calc(14px + 6px);
    background-image: url(~@/static/image/icon_wenjian.png);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: left;
    color: #0062FF;
    cursor: pointer;
}
</style>