export default {
    data() {
        return {
            list: [],
            listParams: {
                keyword: '',
                keyword2: ''
            }
        };
    },
    mounted(){
        this.getList()
    },
    methods: {
        async getList() {
            const { current, size } = this.$refs.pagination
            const { listParams } = this
            const { status, data } = await this.$api.getEnforceList({ current, size, ...listParams })
            if (status == 200) {
                this.list = data.records;
                this.$refs.pagination.createPagination(data)
            }

        }
    }
}