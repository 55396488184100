<template>
    <div class="subject_box">
        <el-tooltip >
            <span class="name">{{ subjectData['subject' + type] }}</span>
            <div slot="content" class="content">
                <p class="content_tit">{{ subjectData['subjectType' + type] == 1 ? '法人' : '自然人' }}信息</p>
                <div class="content_line">
                    <p class="lable">{{ type == 'A' ? '申请方：' : '被申请方：' }}</p>
                    <p class="value">{{ subjectData['subject' + type] || '--' }}</p>
                </div>
                <div class="content_line" v-if="subjectData['subjectType' + type] == 1 && type == 'A'">
                    <p class="lable">{{ type == 'A' ? '申请方联系人：' : '被申请方联系人：' }}</p>
                    <p class="value">{{ subjectData['contact' + type] || '--' }}</p>
                </div>
                <div class="content_line">
                    <p class="lable">{{ type == 'A' ? '申请方电话：' : '被申请方电话：' }}</p>
                    <p class="value">{{ subjectData['phone' + type] || '--' }}</p>
                </div>
                <div class="content_line">
                    <p class="lable">{{ type == 'A' ? '申请方微信：' : '被申请方微信：' }}</p>
                    <p class="value">{{ subjectData['weixin' + type] || '--' }}</p>
                </div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>

export default {
    props: {
        subjectData: {
            type: Object,
            default: () => ({
                subjectTypeA: null,
                subjectA: '',
                contactA: '',
                phoneA: '',
                weixinA: '',
                subjectTypeB: null,
                subjectB: '',
                phoneB: '',
                weixinB: '',
            })
        },
        type: {
            type: String,
            default: 'A'
        }
    }
}
</script>

<style lang="scss" scoped>
#tooltip {
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 13px;
}

.content {
    width: 330px;
    padding: 0 14px;

    .name {
        cursor: pointer;
    }

    &_tit {
        line-height: 40px;
        color: #fff;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }

    &_line {
        display: flex;
        justify-content: space-between;
        line-height: 40px;

        &_lable {
            opacity: 0.6;
        }

        &_value {}
    }

}
</style>